import { QuestionContentProps } from "../types/question";

export const generalQuestions: QuestionContentProps[] = [
  { text: "Are you a morning or night productive person?" },
  { text: "What is rewarding right now about your work or creative practice?" },
  {
    text: "What's a useful skill you've learned from experience, that a course or degree won't teach you?",
  },
  { text: "Where do you feel most creative?" },
  { text: "What do you hope to do more of?" },

  { text: "What made you want to work in the creative industry?" },
  { text: "What motivates you?" },
  { text: "What has been the most challenging project to work on?" },
  { text: "What do you know now that you wish you had known earlier?" },
  { text: "What is a challenge right now in your work or creative practice?" },
  { text: "What's your #1 interview tip?" },
  { text: "What is your design pet-peeve?" },
  {
    text: "What do you want your legacy or impact to be?",
    author: "Kel Lauren, Ep 149",
    episodeLink:
      "https://creators.spotify.com/pod/show/australian-design-radio/episodes/Ep149-Kel-Lauren--Elliot-Ulm-e2nfqe4/a-abg8erv",
  },
  { text: "What would you like to see change in the creative industry?" },
  { text: "What have you been experimenting with recently?" },
  { text: "What has been the most fulfilling project you've worked on?" },
  { text: "What has been the biggest learning curve in your career?" },
  {
    text: "What's the biggest reaction someone has had to your work?",
    author: "Episode 151",
    episodeLink:
      "https://creators.spotify.com/pod/show/australian-design-radio/episodes/Ep151-Richie-Meldrum--Paul-Balfe--Kiel-Tillman-Pennybridge--TDC24-e2ojger/a-abhll4h",
  },
  { text: "What's your unpopular design opinion?" },
  { text: "Who do you look up to, and how have they influenced your work?" },
  { text: "What do you want to do less of?" },
  {
    text: "Do you solve creative problems while you sleep?",
    author: "Carmen Angelillo, Ep152",
    episodeLink:
      "https://creators.spotify.com/pod/show/australian-design-radio/episodes/Ep152-Carmen-Angelillo-Niceshit--Mat-Voyce--TDC24-e2osvc8/a-abi3fa3",
  },
  { text: "How did you get into this industry?" },
  { text: "What's your go-to design tool?" },
  { text: "When in the day are you most creative?" },
];

// update to have string, author, episode numbner and link
