import createStyle from "@josephmark/createstyle";

export const BodyXSmall = createStyle(
  "p",
  "text-sans font-medium text-[0.75rem] leading-[1rem]"
); // 12px, 18px
export const BodySmall = createStyle(
  "p",
  "text-sans font-medium text-[0.875rem]"
); // 14px, 20px
export const BodyLarge = createStyle(
  "p",
  "text-sans font-medium text-[1.625rem] leading-[1.8rem]"
); //
