import { FC, useCallback, useState } from "react";
import { CardProps } from "./types";
import clsx from "clsx";
import { BodyLarge, BodySmall, BodyXSmall } from "../../text";
import { motion, useMotionValue, useTransform } from "framer-motion";

const VELOCITY_THRESHOLD = 50;
const OFFSET_THRESHOLD = 50;

const CardTemplate: FC<{
  text: string;
  author?: string;
  className?: string;
  linkUrl?: string;
}> = ({ text, author, className, linkUrl }) => (
  <div
    className={clsx(
      "w-full h-full rounded-3xl border bg-white text-blue p-4",
      className
    )}
  >
    <div
      className={clsx(
        "h-full w-full rounded-2xl border overflow-y-auto",
        "bg-white text-blue text-center p-6",
        "flex flex-col justify-between"
      )}
    >
      <BodyXSmall className="font-normal">ADR+CONVOS</BodyXSmall>
      <div>
        <BodyLarge>{text}</BodyLarge>
        {author && <BodySmall className="mt-8">{author}</BodySmall>}
      </div>
      {linkUrl ? (
        <a
          className="bg-orange text-white rounded-2xl text-xs inline-block p-1 px-3 w-fit mx-auto"
          href={linkUrl}
          target="_blank"
        >
          Listen to episode
        </a>
      ) : (
        <BodyXSmall className="font-normal">
          by Australian Design Radio
        </BodyXSmall>
      )}
    </div>
  </div>
);

export const Card: FC<CardProps> = ({
  cardContent,
  className,
  isStatic,
  onCardSwiped,
}) => {
  const x = useMotionValue(0);
  const rotateValue = useTransform(x, [-1000, 0, 0, 1000], [-4, 0, 0, 4]);

  if (isStatic) {
    return CardTemplate({ text: cardContent.text, className });
  }

  return (
    <motion.div
      drag="x"
      dragConstraints={{ left: -20, right: 20 }}
      onDrag={(event, info) => {
        x.set(info.offset.x);
      }}
      onDragEnd={(event, info) => {
        const offset = info.offset.x;
        const velocity = info.velocity.x;

        if (
          (offset > OFFSET_THRESHOLD || offset < -OFFSET_THRESHOLD) &&
          (velocity > VELOCITY_THRESHOLD || velocity < -VELOCITY_THRESHOLD)
        ) {
          // TODO animate out
          onCardSwiped && onCardSwiped();
        }
      }}
      whileDrag={{ scale: 0.95 }}
      style={{ rotate: rotateValue }}
      className={clsx("cursor-pointer h-full w-full", className)}
    >
      <CardTemplate
        text={cardContent.text}
        author={cardContent.author}
        linkUrl={cardContent.episodeLink}
      />
    </motion.div>
  );
};

export default Card;
